@import "style-functions";
@import "style-config";

$color-green-dark: #296D3F;
$color-yellow : #FAC654;

/* Custom pages */
.custom {
  position: relative;
  z-index: 1;

  h1 {
    color: $color-green-dark;
    @include font-size(42px);
    font-weight: 200;
    margin: 40px 0 15px;
    font-family: "Pacifico";
  }
  h2 {
    color: $color-secondary;
    @include font-size(24px);
    font-weight: 400;
    margin-bottom: 15px;
  }

  .button {
    display: inline-block;
    padding: 15px 25px;
    background-color: $color-highlight;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    @include transition(all ease-in-out 0.2s);

    &:hover {
      background-color: $color-yellow;
    }
  }

  p.text, p.text-citation {
    @include font-size(18px);
    line-height: 22px;
    margin: 25px 0;
  }
  p.text-citation {
    font-style: italic;
  }

  .separator {
    background-color: $color-yellow;
    width: 150px;
    border-radius: 5px;
    height: 3px;
    margin: 50px auto;
  }

  .teaser {
    position: relative;
    max-width: 960px;
    margin: 40px auto;
    display: table;

    > div {
      float: left;
      max-width: 50%;
      margin-right: 50px;

      @include custom(1100) {
        margin: 0 0 20px 30px;
        max-width: 60%;
      }
    }

    > img {
      float: left;
      margin-left: 50px;
    }
    > img:first-of-type {
      @include custom(1100) {
        display: block;
        margin: 20px auto;
        float: none;
        width: 100%;
        padding: 0 20px;
        max-width: 300px;
      }
    }
    > img:last-of-type {
      position: absolute;
      right: 15px;
      top: -83px;
    }
    .signature {
      @include font-size(14px);
      color: $color-secondary;
      margin-bottom: 15px;

      span {
        color: $color-base;
        font-style: italic;
      }
    }
  }

  .flex-col {
    display: flex;
    max-width: 960px;
    margin: 40px auto;

    @include custom(750) {
      flex-direction: column;
    }

    > div {
      margin-right: 50px;

      @include custom(1100) {
        margin: 0 20px 20px 30px;
      }
    }

    > img {
      flex-grow: 0;
      flex-shrink: 0;

      @include custom(750) {
        max-width: 300px;
        margin: 0 auto;
        display: block;
      }
    }
  }

  .partners {

    a > img {
      margin-top: 10px;
      height: 100px;
      margin-left: 50px;
    }

    a:hover {
      background: none;
    }
  }

  .img-center {
    display: block;
    width: 100%;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }

  .icones-values {
    margin: -160px auto 50px;
    max-width: 860px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    @include custom(860) {
      margin: 0 auto 50px;
      flex-wrap: wrap;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      text-align: center;

      img {
        width: 75px;
        height: 75px;
      }

      p:first-of-type {
        color: white;
        @include font-size(16px);
        font-weight: 600;
        text-transform: uppercase;
        color: $color-green-dark;
        padding: 8px 10px;
        border-bottom: solid 1px $color-green-dark;
      }

      p:last-of-type {
        @include font-size(14px);
        color: $color-secondary;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        max-width: 160px;
      }
    }
  }

  .icones-values-m {
    margin-top: 100px;

    @include custom(860) {
      margin-top: 0;
    }
  }
  .icones-values-l {
    margin-top: 200px;

    @include custom(860) {
      margin-top: 0;
    }
  }



  .home-imgs {
    width: 100%;
    max-width: 1000px;
    margin: 25px auto 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    gap: 25px;

    @include sm {
      display: block;
      width: 100%;
      padding: 0;
    }

    div {
      @include sm {
        margin: 20px;
      }
      img {
        width: 100%;
        object-fit: cover;
        max-width: 300px;
        border-radius: 10px;

        @include sm {
          max-width: initial;
        }
      }
    }
  }

  .home-imgs > div {
    position: relative;

    &:hover > div {
      @include opacity(1);
    }

    > div {
      @include opacity(0);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(68, 155, 35, 0.4);
      @include transition(all ease-in-out 0.2s);
      cursor: pointer;
      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        text-align: center;
        color: white;
        font-weight: bold;
        padding: 20px 20px 20px;
      }

      a:hover {
        background: none;
      }
    }
  }

  .where-to-buy {
    display: flex;
    max-width: 960px;
    margin: 0 auto;

    @include custom(900) {
      display: initial;
      max-width: 100%;
    }

    > div {
      text-align: center;
      padding: 30px;
      width: 50%;

      @include custom(900) {
        width: initial;
      }

      h1 {
        margin-top: 0;
      }
      p {
        font-weight: bold;
        @include font-size(18px);
      }
      img {
        margin-top: 20px;
        border-radius: 10px;

        @include custom(900) {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }

  .home-select-products {
    margin: 20px 0 40px 0;
  }

  a {
    color: $color-highlight;
    text-decoration: underline;
    padding: 0 5px;

    &:hover {
      text-decoration: none;
      background-color: $color-highlight-light;
      border-radius: 10px;
    }
  }

  .button {
    display: table;
    margin: 0 auto;
  }
}

.spiruline {
  text-align: center;

  .img-center {
    width: initial;
  }

  .history,
  .composition,
  .profits,
  .consumption {
    margin: 0 80px;

    @include custom(1366) {
      margin: 30px;
    }

    .text {
      margin-left: 180px;
      margin-right: 180px;

      @include custom(900) {
        margin-left: 30px;
        margin-right: 30px;
      }

      @include sm {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  .icones-values {
    margin: 50px auto;

    @include custom(1366) {
      padding: 0 30px;
    }

    div {

      p:first-of-type {
        color: $color-green-dark;
        @include font-size(16px);
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: solid 1px $color-green-dark;
        background-color: initial;
        padding: 8px 10px;
      }
    }
  }

  .composition {
    img:first-of-type {
      margin-top: 50px;
    }
    img:last-of-type {
      margin-top: -140px;
    }

    img {
      @include custom(660) {
        width: 100%;
      }
    }
  }

  .consumption {
    .icones-values {
      div {
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .subcover {
    max-width: 960px;
    margin: 0 auto;
  }
}


.about {
  .teaser {
    @include md {
      margin-top: 0;
    }

    img:last-of-type {
      margin-right: 30px;
    }

    > img:last-of-type.img-ferme {
      margin-top: 50px;
      margin-right: 0;
      right: 0;

      @include custom(1100) {
        width: 40%;
      }
      @include custom(600) {
        display: none;
      }
    }
  }

  .teaser:nth-child(3) {
    div {
      max-width: 50%;

      @include custom(600) {
        max-width: 100%;
      }
    }
  }
}


.faq {

  .teaser {
    @include lg {
      margin-top: 0;
    }

    div {
      @include lg {
        max-width: 100%;
      }
    }

    img:first-of-type {
      @include lg {
        display: block;
        float: none;
        margin: 0 auto;
      }
    }

    img:last-of-type {
      @include lg {
        display: none;
      }
    }
  }

  .questions {
    h1 {
      margin-left: 150px;

      @include lg {
        margin-left: 30px;
        margin-right: 30px;
      }
    }
    div {
      margin-bottom: 20px;
      margin-left: 180px;
      margin-right: 180px;
      padding: 20px 30px;
      border-radius: 20px;
      background-color: #f1f1f1;

      @include lg {
        margin-left: 20px;
        margin-right: 20px;
      }

      h2 {
        margin-top: 15px;
        padding-bottom: 10px;
        border-bottom: dotted 1px $color-secondary;
      }

      p {
        @include font-size(18px);
        line-height: 22px;
        margin: 25px 15px;
      }
      ul {
        margin-left: 50px;

        @include lg {
          margin-left: 15px;
        }

        li {
          list-style: circle;
          @include font-size(18px);
          line-height: 22px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.terms-of-sales {
  text-align: center;

  h1 {
    color: $color-highlight;
    font-weight: normal;
    @include font-size(35px);
  }

  h2 {
    color: $color-highlight;
    font-weight: normal;
    @include font-size(25px);
    margin-bottom: 10px;
    margin-top: 30px;
  }

  h3 {
    color: $color-highlight;
    margin-bottom: 6px;
  }

  .content {
    width: 100%;
    max-width: 900px;
    margin: 50px auto;
    text-align: justify;

    @include sm {
      margin: 40px auto;
      padding: 0 30px;
    }

    p {
      margin: 0 auto;
      @include font-size(16px);
      line-height: 23px;
      margin-bottom: 10px;
    }

    img {

      width: 70%;
      margin-bottom: 10px;

      @include sm {
        width: 90%;

      }
    }
  }
}

